import { CardGroup, Card, Spinner } from 'react-bootstrap';
import './AccountDetails.scss';

const AccountDetails = ({currentAccount, accountNFTs, tokensToClaim, isLoading}) => {
  return (
    <>
      <div className="App flex justify-center">
        <div className="py-5 container">
          <CardGroup>
            <Card className="Card-details">
              <Card.Body>
                <Card.Title className="Details-title">
                  {currentAccount ? (isLoading ? <Spinner animation="border" /> : tokensToClaim) : '-'}
                </Card.Title>
                {!currentAccount &&
                  <Card.Subtitle className="my-3 text-muted">* Connect your Wallet first</Card.Subtitle>
                }
                <Card.Subtitle className="my-3 text-muted">Baby Milk Tokens available to claim</Card.Subtitle>
                <Card.Text>
                  Number of Baby Milk Tokens available for you to claim on Polygon network.
                </Card.Text>
                <Card.Link
                  href="https://polygonscan.com/address/0x8B25b7e8DC85502B01cBf72A4F859140aC7B0A19"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Baby Milk Contract
                </Card.Link>
                <Card.Link
                  href="https://etherscan.io/address/0xf61690ed5334d00ba7e03ff96dacf1fcf0685169"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Baby Deluxe Contract
                </Card.Link>
              </Card.Body>
            </Card>
            <Card className="Card-details">
              <Card.Body>
                <Card.Title className="Details-title">
                  {currentAccount ? (isLoading ? <Spinner animation="border" /> : accountNFTs.length) : '-'}
                </Card.Title>
                {!currentAccount &&
                  <Card.Subtitle className="my-3 text-muted">* Connect your Wallet first</Card.Subtitle>
                }
                <Card.Subtitle className="my-3 text-muted">Baby Deluxe NFTs owned</Card.Subtitle>
                <Card.Text>
                  For each Baby Deluxe NFT you own you will get Baby Milk tokens every day.
                </Card.Text>
                {currentAccount &&
                  <Card.Link
                    href="#nft-section"
                  >
                    Your Baby Deluxe NFTs
                  </Card.Link>
                }
              </Card.Body>
            </Card>
            <Card className="Card-details">
              <Card.Body>
                <Card.Title className="Details-title">
                  {currentAccount ? (isLoading ? <Spinner animation="border" /> : accountNFTs.filter(nft => nft.isDoubleMilk).length) : '-'}
                </Card.Title>
                {!currentAccount &&
                  <Card.Subtitle className="my-3 text-muted">* Connect your Wallet first</Card.Subtitle>
                }
                <Card.Subtitle className="my-3 text-muted">Double Milk NFTs</Card.Subtitle>
                <Card.Text>
                  Each Double Milk Baby Deluxe NFT will earn x2 Baby Milk tokens ('x2 Milk' image label used).
                </Card.Text>
                {currentAccount &&
                  <Card.Link
                    href="#nft-section"
                  >
                    Your Baby Deluxe NFTs
                  </Card.Link>
                }
              </Card.Body>
            </Card>
          </CardGroup>
        </div>
      </div>
    </>
  )
};

export default AccountDetails;