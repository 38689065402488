import '../App.scss';

const ChainSwitch = ({ message, btnTitle, switchHandler }) => {
  return (
    <div className="App flex justify-center">
      <div className="py-4 container">
      <h2>{message}</h2>
        <div className="Button-container">
          <a
            className="Btn"
            role="button"
            onClick={switchHandler}
          >
            {btnTitle}
          </a>
        </div>
      </div>
    </div>
  );
};

export default ChainSwitch;