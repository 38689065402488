import { Container } from 'react-bootstrap';
import ClaimTokensPage from './pages/ClaimTokensPage';
import Footer from './components/Footer';
import './App.scss';

const App = () => {
  return (
    <>
      <Container fluid className="App App-container">
        <header>
          <img className="App-logo my-5" alt="logo" src="/logo.png" width="260" height="110"></img>
        </header>
        <ClaimTokensPage />
        <Footer />
      </Container>
    </>
  );
};

export default App;
