import { CHAIN_ID_LOCAL_STORAGE_KEY } from './constants';

export const isCurrentNetwork = (ethereum, chainId) => {
  const currentChainId = ethereum && ethereum.chainId ? ethereum.chainId : getFromLocalStorage(CHAIN_ID_LOCAL_STORAGE_KEY);
  return ethereum && parseInt(currentChainId) === chainId;
};

export const setToLocalStorage = (key, value) => {
  localStorage.setItem(key, typeof value === 'object' ? JSON.stringify(value) : value);
};

export const getFromLocalStorage = (key) => {
  return localStorage.getItem(key);
};

export const setToLocalStorageWithExpiry = (key, value, ttl) => {
	const item = {
		value,
		expiry: Date.now() + ttl,
	};

	localStorage.setItem(key, JSON.stringify(item));
};

export const getFromLocalStorageWithExpiry = (key) => {
	const itemStr = localStorage.getItem(key);

	if (!itemStr) {
		return null;
	}

	const item = JSON.parse(itemStr);

	if (Date.now() > item.expiry) {
		localStorage.removeItem(key);
		return null;
	}

	return item.value;
};