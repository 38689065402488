import { POST } from './ApiService';

const baseUrl = '/v1';

export default class TokenService {
	static async calculateBalance(payload, callback, callbackError, onTheFlyData) {
		POST({
			url: `${baseUrl}/balance`,
			body: payload
		}, callback, callbackError, onTheFlyData
		);
	}

	static async claimTokens(payload, callback, callbackError, onTheFlyData) {
		POST({
			url: `${baseUrl}/claimTokens`,
			body: payload
		}, callback, callbackError, onTheFlyData
		);
	}
}