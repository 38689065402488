import { useContext, useState, useEffect } from 'react';
import { Spinner } from 'react-bootstrap';
import AccountDetails from '../components/AccountDetails';
import ConnectMetamask from '../components/ConnectMetamask';
import { TransactionContext } from '../context/TransactionContext';
import appConfig from '../config/app-config';
import ClaimTokens from '../components/ClaimTokens';
import AccountNFTs from '../components/AccountNFTs';
import TokenService from '../services/TokenService';
import {
  getFromLocalStorage,
  getFromLocalStorageWithExpiry,
  isCurrentNetwork,
  setToLocalStorage,
  setToLocalStorageWithExpiry
} from '../utils/util';
import {
  CHAIN_ID_LOCAL_STORAGE_KEY,
  CLAIM_COMPLETED_LOCAL_STORAGE_KEY,
  CLAIM_COMPLETED_LOCAL_STORAGE_TTL_MS,
  ETHEREUM_MAINNET_CHAIN_ID,POLYGON_MAINNET_CHAIN_ID
} from '../utils/constants';
import ChainSwitch from '../components/ChainSwitch';
import ImportTokens from '../components/ImportTokens';
import '../App.scss';

const ClaimTokensPage = () => {
  const {
    isMetaMaskInstalled,
    currentAccount,
    readBlockchainData,
    filterEvents,
    getBlockTimestamp,
    switchToPolygonChain,
    switchToEthereumMainnetChain
  } = useContext(TransactionContext);

  const [accountNFTs, setAccountNFTs] = useState([]);
  const [balance, setBalance] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [claimCompleted, setClaimCompleted] = useState(Boolean(getFromLocalStorageWithExpiry(CLAIM_COMPLETED_LOCAL_STORAGE_KEY)));

  getFromLocalStorage(CHAIN_ID_LOCAL_STORAGE_KEY) || setToLocalStorage(CHAIN_ID_LOCAL_STORAGE_KEY, `0x${ETHEREUM_MAINNET_CHAIN_ID.toString(16)}`);
  const isPolygonMainnet = isCurrentNetwork(window.ethereum, POLYGON_MAINNET_CHAIN_ID);
  const isEthereumMainnet = isCurrentNetwork(window.ethereum, ETHEREUM_MAINNET_CHAIN_ID);

  const loadAccountNFTsAndTokenBalance = async () => {
    setIsLoading(true);
    const numberOfNFTsOwnedByAccount = await readBlockchainData('balanceOf', [currentAccount]);
    const nftIndexes = [...Array(parseInt(numberOfNFTsOwnedByAccount)).keys()];

    const nfts = await Promise.all(nftIndexes.map(async index => {
      const tokenId = await readBlockchainData('tokenOfOwnerByIndex', [currentAccount, index]);
      const response = await fetch(`${appConfig.baseMetadataUrl}/metadata/${tokenId}`);
      const metadata = await response.json();

      return {
        id: tokenId,
        name: metadata.name,
        description: metadata.description,
        imageUrl: `${appConfig.baseMetadataUrl}/images/${metadata.image.split('/').pop()}`,
        isDoubleMilk: isDoubleMilkNFT(metadata)
      }
    }));

    setAccountNFTs(nfts);
    loadTokenBalance(nfts);
  };

  const isDoubleMilkNFT = (nftMetadata) => {
    return nftMetadata.attributes &&
      nftMetadata.attributes.some((attr) => attr.trait_type.toLowerCase() === 'bonus' && attr.value.toLowerCase() === 'double milk');
  };

  const loadTokenBalance = async (nfts) => {
    const tokenIds = nfts.map((nft) => nft.id);

    const resultsFilterEvents = await filterEvents(
      'Transfer',
      [null, currentAccount, tokenIds]
    );

    const lastEventIndexes = tokenIds.map(
      tokenId => resultsFilterEvents.map(event => event.args[2]._hex).lastIndexOf(tokenId._hex)
    );

    const blockNumbers = lastEventIndexes.map(index => resultsFilterEvents[index].blockNumber);

    let blockTimestampMapping = {};

    const eventTimestamps = await Promise.all(blockNumbers.map(async (blockNumber) => {
      if (blockTimestampMapping[blockNumber] === undefined) {
        blockTimestampMapping[blockNumber] = await getBlockTimestamp(blockNumber);
      }
      return blockTimestampMapping[blockNumber];
    }));

    const nftsRequestData = nfts.map((nft, index) => ({
      tokenId: parseInt(nft.id).toString(),
      ownedFromTimestampSeconds: eventTimestamps[index].toString(),
      isDoubleMilk: nft.isDoubleMilk
    }));

    const payload = {
      address: currentAccount,
      nfts: nftsRequestData
    };

    calulateTokenBalance(payload);
  };

  const calulateTokenBalance = async (payload) => {
    TokenService.calculateBalance(
      payload, calculateBalanceSuccess, calculateBalanceError, payload.address
    );
  };

  const calculateBalanceSuccess = (response) => {
    setBalance(response.balance);
    setIsLoading(false);
  };

  const calculateBalanceError = (error, onTheFlyData) => {
    console.error(`Error while calculating token balance for address: ${onTheFlyData}`);
    setIsLoading(false);
  };

  const claimTokens = async () => {
    setIsLoading(true);
    const payload = {
      address: currentAccount,
      nftIds: accountNFTs.map(nft => parseInt(nft.id).toString())
    };
    TokenService.claimTokens(payload, claimTokensSuccess, claimTokensError, payload.address);
  };

  const claimTokensSuccess = () => {
    setBalance(0);
    setToLocalStorageWithExpiry(CLAIM_COMPLETED_LOCAL_STORAGE_KEY, true, CLAIM_COMPLETED_LOCAL_STORAGE_TTL_MS);
    setClaimCompleted(true);
    setIsLoading(false);
  };

  const claimTokensError = (error, onTheFlyData) => {
    console.error(`Error while claiming tokens for address: ${onTheFlyData}`);
    setIsLoading(false);
  };

  const getClaimTokensContent = () => {
    if (!isEthereumMainnet || !currentAccount) {
      return null;
    }

    if (isLoading) {
      return <Spinner animation="border" />;
    }

    if (claimCompleted) {
      return <ChainSwitch
        message='Switch to Polygon network in order to import claimed tokens to your wallet'
        btnTitle='Switch to Polygon'
        switchHandler={switchToPolygonChain}
      />;
    }

    return <ClaimTokens tokensToClaim={balance} claimTokens={claimTokens} isLoading={isLoading} />;
  };

  const getAccountDetailsOrEthereumSwitchContent = () => {
    if (isEthereumMainnet) {
      return (
        <AccountDetails
          currentAccount={currentAccount}
          accountNFTs={accountNFTs}
          tokensToClaim={balance}
          isLoading={isLoading}
        />
      );
    }

    return (
      <ChainSwitch
        message='Switch to Ethereum Mainnet'
        btnTitle='Switch'
        switchHandler={switchToEthereumMainnetChain}
      />
    );
  };

  const getContentBasedOnCurrentNetwork = () => {
    if (!isMetaMaskInstalled) {
      return (
        <>
          <ConnectMetamask />
          <AccountDetails
            currentAccount={currentAccount}
            accountNFTs={accountNFTs}
            tokensToClaim={balance}
            isLoading={isLoading}
          />
        </>
      );
    }

    if (isPolygonMainnet && currentAccount) {
      return (
        <>
          <ImportTokens setClaimCompleted={setClaimCompleted} />
          <ChainSwitch
            message='Switch back to Ethereum Mainnet'
            btnTitle='Back to Ethereum'
            switchHandler={switchToEthereumMainnetChain}
          />
        </>
      );
    }

    return (
      <>
        <ConnectMetamask />
        {getAccountDetailsOrEthereumSwitchContent()}
        {getClaimTokensContent()}
        {(isEthereumMainnet && currentAccount) &&
          <AccountNFTs accountNFTs={accountNFTs} contractAddress={appConfig.contractAddress} />}
      </>
    )
  };

  useEffect(() => {
    (isMetaMaskInstalled && currentAccount) ? loadAccountNFTsAndTokenBalance() : setAccountNFTs([]);
  }, [isMetaMaskInstalled, currentAccount]);

  return (
    <div className="Content-wrapper">
      {getContentBasedOnCurrentNetwork()}
    </div>
  );
};

export default ClaimTokensPage;