import { Row, Badge, Col, Card } from 'react-bootstrap';

const AccountNFTs = ({ accountNFTs, contractAddress }) => {
  return (
    <div className="App flex justify-center" id="nft-section">
      {accountNFTs.length > 0 ?
        <h2>Your Baby Deluxe NFTs</h2> : <div className="H2-info"><h2>No Baby Deluxe NFTs owned</h2></div>}
      {accountNFTs.length > 0 &&
        <div className="px-5 pb-5 container">
          <Row xs={1} md={2} lg={4} className="g-5 py-5">
            {accountNFTs.map((nft, idx) => (
              <Col key={idx}>
                <a
                  className="Card"
                  href={`https://opensea.io/assets/${contractAddress}/${nft.id}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Card className="Card">
                    <Card.Img variant="top" src={nft.imageUrl} alt={nft.name} />
                    {nft.isDoubleMilk &&
                      <Card.ImgOverlay>
                        <Card.Title className="Image-title">
                          <Badge bg="info">x2 Milk</Badge>
                        </Card.Title>
                      </Card.ImgOverlay>
                    }
                    <Card.Footer>{nft.name}</Card.Footer>
                  </Card>
                </a>
              </Col>
            ))}
          </Row>
        </div>
      }
    </div>
  )
};

export default AccountNFTs;