import { useContext } from 'react';
import { TransactionContext } from '../context/TransactionContext';
import { CLAIM_COMPLETED_LOCAL_STORAGE_KEY } from '../utils/constants';
import '../App.scss';

const ImportTokens = ({ setClaimCompleted }) => {
  const { importTokens } = useContext(TransactionContext);

  const importHandler = () => {
    importTokens();
    setClaimCompleted(false);
    localStorage.removeItem(CLAIM_COMPLETED_LOCAL_STORAGE_KEY);
  };

  return (
    <div className="App flex justify-center">
      <div className="py-4 container">
      <h2>Import Baby Milk tokens to your Metamask wallet</h2>
        <div className="Button-container">
          <a
            className="Btn"
            role="button"
            onClick={importHandler}
          >
            Import
          </a>
        </div>
      </div>
    </div>
  );
};

export default ImportTokens;