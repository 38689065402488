// For more info about chain IDs check https://chainlist.org/
export const ETHEREUM_MAINNET_CHAIN_ID = 1;
export const POLYGON_MAINNET_CHAIN_ID = 137;

export const CHAIN_ID_LOCAL_STORAGE_KEY = 'chainId';
export const CLAIM_COMPLETED_LOCAL_STORAGE_KEY = 'claimCompleted';
export const CLAIM_COMPLETED_LOCAL_STORAGE_TTL_MS = 300_000; // 5 min

export const METAMASK_PROVIDER_USER_REJECTED_REQUEST_ERROR_CODE = 4001;
// This error code indicates that the chain has not been added to MetaMask.
export const CHAIN_NOT_ADDED_ERROR_CODE = 4902;