import { useContext } from 'react';
import { TransactionContext } from '../context/TransactionContext';
import { isCurrentNetwork } from '../utils/util';
import { ETHEREUM_MAINNET_CHAIN_ID } from '../utils/constants';
import '../App.scss';

const Connect = ({ account }) => {
  const { isMetaMaskInstalled, connectWallet } = useContext(TransactionContext);
  const isEthereumMainnet = isCurrentNetwork(window.ethereum, ETHEREUM_MAINNET_CHAIN_ID);

  const getConnectContent = () => {
    if (account) {
      return (
        <>
          <h2>Check your account on OpenSea</h2>
          <div className="Button-container">
            <a
              role="button"
              className="Btn"
              href={`https://opensea.io/${account}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              OpenSea Account
            </a>
          </div>
        </>
      )
    }

    return (
      <>
        <h2>Connect your Metamask wallet to claim Baby Milk tokens</h2>
        <div className="Button-container">
          <a
            role="button"
            className={isMetaMaskInstalled && isEthereumMainnet ? 'Btn' : 'Btn Disabled'}
            onClick={connectWallet}
          >
            Connect
          </a>
        </div>
        {!isEthereumMainnet ? (
          <div>* Switch to Ethereum Mainnet in order to connect</div>
        ) : null}
      </>
    )
  };

  return getConnectContent();
};

export default Connect;