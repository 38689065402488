import '../App.scss';

const ClaimTokens = ({ tokensToClaim, claimTokens }) => {
  return (
    <div className="App flex justify-center">
      <div className="py-4 container">
        {tokensToClaim > 0 ? 
          <h2>{`Claim ${tokensToClaim} Baby Milk tokens`}</h2> :
          <h2>No Baby Milk tokens to claim</h2>}
        <div className="Button-container">
          <a
            className={tokensToClaim > 0 ? 'Btn' : 'Btn Disabled'}
            role="button"
            onClick={claimTokens}
          >
            Claim
          </a>
        </div>
      </div>
    </div>
  );
};

export default ClaimTokens;