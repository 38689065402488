import { useContext } from 'react';
import { TransactionContext } from '../context/TransactionContext';
import Connect from './Connect';

const ConnectMetamask = () => {
  const { currentAccount, isMetaMaskInstalled } = useContext(TransactionContext);

  return (
    <div className="App flex justify-center">
      <div className="py-4 container">
        {isMetaMaskInstalled ? (
          <Connect account={currentAccount} />
        ) : (
          <h2>
            <a
              href="https://metamask.io/" 
              target="_blank"
              rel="noopener noreferrer"
            >
              MetaMask
            </a> wallet is required to claim Baby Milk tokens
          </h2>
        )}
      </div>
    </div>
  );
};

export default ConnectMetamask;