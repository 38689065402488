import React, { useEffect, useState } from 'react';
import { ethers } from 'ethers';
import appConfig from '../config/app-config';
import { setToLocalStorage } from '../utils/util';
import {
  CHAIN_ID_LOCAL_STORAGE_KEY,
  CHAIN_NOT_ADDED_ERROR_CODE,
  ETHEREUM_MAINNET_CHAIN_ID,
  METAMASK_PROVIDER_USER_REJECTED_REQUEST_ERROR_CODE,
  POLYGON_MAINNET_CHAIN_ID
} from '../utils/constants';

export const TransactionContext = React.createContext();

const { ethereum } = window;
const { contractAddress, contractABI } = appConfig;
const isMetaMaskInstalled = ethereum && ethereum.isMetaMask;
let provider, contract;

if (isMetaMaskInstalled) {
  provider = new ethers.providers.Web3Provider(ethereum);
  contract = new ethers.Contract(contractAddress, contractABI, provider);
}

export const TransactionProvider = ({ children }) => {
  const [currentAccount, setCurrentAccount] = useState('');

  const checkIfWalletIsConnected = async () => {
    try {
      const accounts = await ethereum.request({ method: 'eth_accounts' });
      if (accounts.length) {
        setCurrentAccount(accounts[0]);
      }
    } catch (error) {
      console.log(error);
      throw new Error('No ethereum object.');
    }
  };

  const connectWallet = async () => {
    try {
      const accounts = await ethereum.request({ method: 'eth_requestAccounts' });
      setCurrentAccount(accounts[0]);
    } catch (error) {
      if (error.code !== METAMASK_PROVIDER_USER_REJECTED_REQUEST_ERROR_CODE) {
        throw new Error('No ethereum object.');
      }
    }
  };

  const filterEvents = async (eventName, params) => {
    const filter = contract.filters[eventName](...params);
    return contract.queryFilter(filter);
  };

  const readBlockchainData = async (functionName, params) => {
    try {
      const response = await contract[functionName](...params);
      return response;
    } catch (error) {
      console.log(error);
      throw new Error('No ethereum object.');
    }
  };

  const getBlockTimestamp = async (blockNumber) => {
    return (await provider.getBlock(blockNumber)).timestamp;
  }

  const switchToPolygonChain = async () => {
    try {
      await ethereum.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: ethers.utils.hexValue(POLYGON_MAINNET_CHAIN_ID) }],
      });
    } catch (switchError) {
      if (switchError.code === CHAIN_NOT_ADDED_ERROR_CODE) {
        try {
          await ethereum.request({
            method: 'wallet_addEthereumChain',
            params: [
              {
                chainId: ethers.utils.hexValue(POLYGON_MAINNET_CHAIN_ID),
                chainName: 'Polygon Mainnet',
                nativeCurrency: {
                  symbol: 'MATIC',
                  decimals: 18
                },
                rpcUrls: [
                  'https://polygon-rpc.com',
                  'https://rpc-mainnet.matic.network',
                  'https://rpc-mainnet.maticvigil.com',
                  'https://rpc-mainnet.matic.quiknode.pro'
                ],
                blockExplorerUrls: ['https://polygonscan.com/']
              },
            ],
          });
        } catch (addError) {
          console.log(addError);
        }
      }
      console.log(switchError);
    }
  };

  const switchToEthereumMainnetChain = async () => {
    try {
      await ethereum.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: ethers.utils.hexValue(ETHEREUM_MAINNET_CHAIN_ID) }],
      });
    } catch (switchError) {
      console.log(switchError);
    }
  }

  const importTokens = async () => {
    try {
      const success = await ethereum.request({
        method: 'wallet_watchAsset',
        params: {
          type: 'ERC20',
          options: {
            address: '0x8B25b7e8DC85502B01cBf72A4F859140aC7B0A19',
            symbol: 'BMILK',
            decimals: 18
          },
        },
      })

      if (!success) {
        console.error('Something went wrong while importing BMILK tokens.');
      }
    } catch (error) {
      console.error('Something went wrong while importing BABYMILK tokens.');
    }
  };

  const handleChainChange = (chainId) => {
    setToLocalStorage(CHAIN_ID_LOCAL_STORAGE_KEY, chainId);
    window.location.reload();
  };

  const handleAccountChange = (accounts) => {
    (accounts && accounts.length) ? setCurrentAccount(accounts[0]) : setCurrentAccount('');
  };

  const registerEventListeners = () => {
    ethereum.on('chainChanged', handleChainChange);
    ethereum.on('accountsChanged', handleAccountChange);
  };

  const removeEventListeners = () => {
    ethereum.remove('chainChanged');
    ethereum.remove('accountsChanged');
  };

  useEffect(() => {
    if (!isMetaMaskInstalled) {
      return;
    }
    checkIfWalletIsConnected();
    registerEventListeners();

    return () => {
      removeEventListeners();
    }
  }, []);

  return (
    <TransactionContext.Provider 
      value={{
        isMetaMaskInstalled,
        currentAccount,
        connectWallet,
        filterEvents,
        readBlockchainData,
        getBlockTimestamp,
        switchToPolygonChain,
        switchToEthereumMainnetChain,
        importTokens
      }}>
      {children}
    </TransactionContext.Provider>
  );
};
